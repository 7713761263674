// 顶部导航的切换按钮-未登录
<template>
  <div class="top_nav_unlogin">
    <div :class="['nav_item', item.hasSelected ? 'item_select' : 'item_normal']" v-for="(item, index) in navArr" :key="index" @click.stop="onItemClick(index)">
      {{ item.title }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navArr: [
        {
          title: "课程中心",
          hasSelected: false
        }
      ]
    };
  },
  created() {},
  methods: {
    //导航条的点击事件
    onItemClick(index) {
      this.$emit("doTopNavClick", index);
    }
  },
  computed: {},
  components: {}
};
</script>
<style lang="scss" scoped>
.top_nav_unlogin {
  display: flex;
  align-items: center;
  .nav_item {
    cursor: pointer;
    width: 122px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-right: 36px;
  }

  //选中的样式
  .item_select {
    background: white;
    border-radius: 18px;
    color: $common_bg;
    font-weight: bold;
  }

  //未选中样式
  .item_normal {
    background: none;
    border-radius: 0px;
    font-weight: bold;
    color: white;
  }
}
</style>
