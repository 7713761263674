// 顶部导航的切换按钮
<template>
  <div class="top_nav">
    <div :class="['nav_item', currPos == index ? 'item_select' : 'item_normal']" v-for="(item, index) in navArr" :key="index" @click.stop="onItemClick(index)">
      {{ item.title }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //顶部的导航数据
      navArr: [
        {
          title: "课程中心"
        },
        { title: "下载" }
        // {
        //   title: "创作中心",
        // },
        // {
        //   title: "教研中心",
        // },
      ],

      //跳转的路由集合
      pathArr: [
        { path: "/LoginContain/CourseCenter" },
        { path: "/LoginContain/DownLoad" }
        // { path: "/LoginContain/WriteCenter" },
        // { path: "/LoginContain/ResearchCenter" }
      ],

      //当前选中的位置,默认是0
      currPos: 0
    };
  },
  created() {
    //初次进入的时候根据路由来同步一下顶部导航条的选中状态
    this.sysNavSateByPath(this.$route.path);
  },

  mounted() {
    this.$bus.on("watchRoutePath", curPath => {
      // console.log("监听到的路由是:", curPath);
      this.sysNavSateByPath(curPath);
    });
  },

  methods: {
    //根据当前路由来同步顶部导航条的状态
    sysNavSateByPath(curPath) {
      //根据路由跳转的路径来确定导航条的选中位置
      let pos = this.getPathPos(curPath);

      this.currPos = pos;

      //在同步一下数据
      this.sysNavState(this.currPos);
    },

    //导航条的点击事件
    onItemClick(index) {
      this.sysNavState(index);
    },

    //同步导航栏的方法
    sysNavState(pos) {
      if (pos != this.currPos) {
        //记录当前点击的位置
        this.currPos = pos;

        //根据索引进行路由的跳转
        let routPath = "";

        switch (pos) {
          case 0:
            routPath = this.pathArr[0].path;
            break;
          case 1:
            routPath = this.pathArr[1].path;
            break;
          case 2:
            routPath = this.pathArr[2].path;
            break;
        }

        if (this.$route.path != routPath) {
          this.$router.push(routPath);
        }
      }
    },

    //根据路由来获取位置的方法
    getPathPos(currPath) {
      let res = -1;
      //在判断下路由是不是选择单元或者选择课程界面
      if (currPath === "/LoginContain/ChooseChapter" || currPath === "/LoginContain/ChooseLesson" || currPath === "/LoginContain/CourseCenter") {
        res = 0;
      } else {
        for (let i = 0; i < this.pathArr.length; i++) {
          if (currPath === this.pathArr[i].path) {
            res = i;
            break;
          }
        }
      }
      return res;
    }
  },
  computed: {},
  components: {},
  beforeDestroy() {
    //销毁时关闭事件
    this.$bus.off("watchRoutePath");
  }
};
</script>
<style lang="scss" scoped>
.top_nav {
  display: flex;
  align-items: center;
  flex: 0;
  .nav_item {
    cursor: pointer;
    width: 122px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-right: 36px;
  }

  //选中的样式
  .item_select {
    background: white;
    border-radius: 18px;
    color: $common_bg;
    font-weight: bold;
  }

  //未选中样式
  .item_normal {
    background: none;
    border-radius: 0px;
    font-weight: bold;
    color: white;
  }
}
</style>
